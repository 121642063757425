export default {
  siteTitle: 'Get bonus',
  blogerName: 'BIGBOX',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/boxref',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/bigbox228',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c10d159f9',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c98835f8b',
      gameTitle: 'Royal High-Road (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/cbccb5602',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cbf63f44f',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c98c1bf25',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c4abb3571',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cccd10674',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c3804b139',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/ce3a18afe',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/ca44bccb3',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>BIGBOX</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'BIGBOX',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  counter: true,
  bonus100: 'Bônus 100%',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
